import handleServerResponse from "../utils/utils";

const latitude = 44.34;
const longitude = 10.99;
const APIkey = "f0cb8d038cd3c243c6ba84c4abfdd0b5";

export const getForecastWeather = () => {
  const weatherApi = fetch(
    `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=imperial&appid=${APIkey}`
  ).then(handleServerResponse);

  return weatherApi;
};

export const parseWeatherData = (data) => {
  const main = data.main;
  const temperature = main && main.temp;
  const fahrenheitTemp = Math.round(temperature);
  const weatherType =
    fahrenheitTemp >= 86
      ? "hot"
      : fahrenheitTemp >= 66 && fahrenheitTemp <= 85
      ? "warm"
      : "cold";
  const weather = {
    temperature: {
      F: Math.round(temperature),
      C: Math.round(((temperature - 32) * 5) / 9),
    },
    weatherType,
  };
  return weather;
};
